/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Layout } from 'antd';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import "../../styles/global.less";
import AppContext from "../../app-context";
import HeaderComponent from "./header"
import FooterComponent from "./footer";

const stripePromise = loadStripe('pk_live_yjLt2l5eA6LXhW2uBEvLeCzW00I7fZe0I4');
// const stripePromise = loadStripe('pk_test_4mkxv5k4llWTX9yJX3IWg9cc00Q9q995sa');
const { Content } = Layout;

const LayoutComponent = (props) => {
  const { children, className, ctaTarget } = props
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleMobileMenu = () => setMobileMenuActive(!mobileMenuActive);

  const context = {
    mobileMenuActive,
    toggleMobileMenu,
    isModalVisible,
    setIsModalVisible,
  };

  const contentStyles = {
    overflow: mobileMenuActive ? 'hidden' : 'auto'
  };

  return (
    <Elements stripe={stripePromise}>
      <Layout className={className || ''}>
        <AppContext.Provider value={context}>
          <HeaderComponent ctaTarget={ctaTarget || '/'} />

          <Content className="sb-content" style={contentStyles}>{children}</Content>

          <FooterComponent/>
        </AppContext.Provider>
      </Layout>
    </Elements>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutComponent;
