import React, { useState, useEffect } from "react"
import { Layout, Row, Col, Button } from "antd"
import { Link } from "gatsby"
import "../../styles/footer.less"

const { Footer } = Layout

const navItems = [
  {
    href: "/home-cleaning",
    label: "Home Cleaning",
  },
  {
    href: "/whats-included",
    label: "What's included",
  },
  {
    href: "/commercial-cleaning",
    label: "Commercial Cleaning",
  },
  {
    href: "/about",
    label: "About us",
  },
  {
    href: "/contact",
    label: "Contact",
  },
]

const FooterComponent = () => {
  const [pagePath, setPagePath] = useState("")

  useEffect(() => {
    setPagePath(checkPath.join(""))
  }, [pagePath])

  let checkPath
  if (typeof window !== `undefined`) {
    checkPath = window.location.pathname.split("/")
  }
  return (
    <Footer className="footer">
      <Row justify="center">
        {navItems.map(item => (
          <Col
            className="footer__link-wrapper"
            xs={22}
            md={11}
            lg={4}
            key={item.href}
          >
            <Link className="footer__link" to={item.href}>
              {item.label}
            </Link>
          </Col>
        ))}

        <Col xs={22} lg={11}>
          <div className="footer__contact">
            <a href="tel:+13038483884">
              {" "}
              (303) 848-3884
              <div className="footer__contact--line" />
            </a>
          </div>
        </Col>

        {/*Placeholder for spacing*/}
        <Col xs={0} lg={2}></Col>

        <Col xs={22} lg={11} style={{ textAlign: "center" }}>
          <Button
            // type="primary"
            block={true}
            size="large"
            className="sb-footer__cta"
          >
            <Link
              to={
                pagePath === "commercial-cleaning"
                  ? "/quotes/commercial-cleaning"
                  : "/quotes/home-cleaning"
              }
            >
              {pagePath === "commercial-cleaning"
                ? "Request a quote"
                : "Instant quote"}
            </Link>
          </Button>
        </Col>

        <Col xs={24} className="footer__trademark">
          ©{new Date().getFullYear()} Denver Cleaning Group
        </Col>
      </Row>
    </Footer>
  )
}

export default FooterComponent
