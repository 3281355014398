import React, { useContext, useEffect, useState } from "react"
import { Link } from "gatsby"
import { Layout, Menu, Row, Col, Drawer, Button } from "antd"
import Burger from "react-css-burger"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

import AppContext from "../../app-context"
const { Header } = Layout

const logo = require("../../images/dcg_logo.png")
const logoMobile = require("../../images/dcg_logo.png")
// const logoMobile = require('../../images/airoom_logo.png');
const phoneMobile = require("../../images/phone-mobile.svg")
const stripes = require("../../images/stripes-on-white-big.png")

const HeaderComponent = props => {
  const { ctaTarget } = props
  const { mobileMenuActive, toggleMobileMenu, galleryVisible } = useContext(
    AppContext
  )
  const [burgerMenu, setBurgerMenu] = useState(null)
  const [isTop, setIsTop] = useState(true)
  const [pagePath, setPagePath] = useState("")

  useEffect(() => {
    const burgerMenu = (
      <Burger
        active={mobileMenuActive}
        onClick={toggleMobileMenu}
        burger="arrow"
      />
    )

    setBurgerMenu(burgerMenu)
    setPagePath(checkPath.join(""))
  }, [mobileMenuActive])

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isTop = currPos.y === 0
      setIsTop(isTop)
    },
    [isTop]
  )
  let checkPath
  if (typeof window !== `undefined`) {
    checkPath = window.location.pathname.split("/")
  }

  return (
    <Header
      className={`sb-header ${isTop ? "" : "scrolled"}`}
      style={{
        opacity: galleryVisible ? 0 : 1,
        visibility: galleryVisible ? "hidden" : "visible",
      }}
    >
      <Row className="sb-header__row">
        <Col xs={6} lg={0} className="mobile-burger-menu">
          {burgerMenu}
        </Col>

        <Col xs={0} lg={{ span: 2, offset: 1 }} style={{ background: "#fff" }}>
          <Link to="/">
            <img
              className="sb-header__logo"
              src={isTop ? logo : logoMobile}
              alt="logo"
            />
          </Link>
        </Col>

        <Col xs={12} lg={0} style={{ textAlign: "center" }}>
          <Link to="/">
            <img className="sb-header__logo" src={logoMobile} alt="logo" />
          </Link>
        </Col>

        <Col xs={6} lg={0} style={{ textAlign: "right", paddingRight: 25 }}>
          <a href="tel:+13038483884">
            <img className="phone-icon" src={phoneMobile} alt="phone icon" />
          </a>
        </Col>

        <Col xs={0} lg={14} xl={14}>
          <Menu
            className={`sb-header__desktop-menu ${isTop ? "" : "scrolled"}`}
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={[""]}
          >
            <Menu.Item key="home-cleaning">
              <Link
                to="/home-cleaning"
                activeClassName="active"
                partiallyActive
              >
                Home Cleaning
              </Link>
            </Menu.Item>
            <Menu.Item key="how-it-works">
              <Link to="/whats-included" activeClassName="active">
                What's Included
              </Link>
            </Menu.Item>
            <Menu.Item key="commercial-cleaning">
              <Link to="/commercial-cleaning" activeClassName="active">
                Commercial Cleaning
              </Link>
            </Menu.Item>
            <Menu.Item key="about-us">
              <Link to="/about" activeClassName="active">
                About us
              </Link>
            </Menu.Item>
            <Menu.Item key="contact">
              <Link to="/contact" activeClassName="active">
                Contact
              </Link>
            </Menu.Item>
          </Menu>
        </Col>

        <Col xs={0} lg={3}>
          <div className="header__contact">
            <a href="tel:+13038483884">
              {" "}
              (303) 848-3884
              <div className="header__contact--line" />
            </a>
          </div>
        </Col>

        <Col xs={0} lg={3}>
          <Button
            type="primary"
            className="primary-cta"
            block={true}
            size="large"
            className="sb-header__cta"
          >
            <Link
              to={
                pagePath === "commercial-cleaning"
                  ? "/quotes/commercial-cleaning"
                  : "/quotes/home-cleaning"
              }
            >
              {pagePath === "commercial-cleaning"
                ? "Request a quote"
                : "Instant quote"}
            </Link>
          </Button>
        </Col>
      </Row>

      <Drawer
        placement="left"
        closable={false}
        onClose={toggleMobileMenu}
        visible={mobileMenuActive}
      >
        <img className="stripes__background" src={stripes} alt="stripes" />
        <div onClick={toggleMobileMenu} className="mobile-nav__item">
          <Link to="/home-cleaning" activeClassName="active" partiallyActive>
            Home Cleaning
          </Link>
        </div>
        <div onClick={toggleMobileMenu} className="mobile-nav__item">
          <Link to="/commercial-cleaning" activeClassName="active">
            Commercial Cleaning
          </Link>
        </div>
        <div onClick={toggleMobileMenu} className="mobile-nav__item">
          <Link to="/about" activeClassName="active">
            About Us
          </Link>
        </div>
        <div onClick={toggleMobileMenu} className="mobile-nav__item">
          <Link to="/whats-included" activeClassName="active">
            What's Inlcluded
          </Link>
        </div>
        <div onClick={toggleMobileMenu} className="mobile-nav__item">
          <Link to="/contact" activeClassName="active">
            Contact
          </Link>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            type="primary"
            className="primary-cta"
            block={true}
            size="small"
          >
            <Link to={ctaTarget}>Instant quote</Link>
          </Button>
        </div>
      </Drawer>
    </Header>
  )
}

export default HeaderComponent
